import * as React from "react"
import { Link } from "gatsby"
import Box from "@mui/material/Box"

const NotFoundPage = () => {
  return (
    <>
        <Box>
            <p>
                Sorry{" "}
                <span role="img" aria-label="Pensive emoji">😔</span> de pagina kon niet worden gevonden.
                <br />
                <Link to="/">Ga naar home</Link>
            </p>
        </Box>
    </>
  )
}

export default NotFoundPage
